import React, { useState } from "react";
import {
  Header,
  Button,
  Grid,
  Container,
  TextArea,
  Form,
  Divider,
  Label,
  Loader
} from "semantic-ui-react";
import PhotoSlideshow from "./PhotoSlideshow";
import "../css/Description.css";
import "../../node_modules/video-react/dist/video-react.css";
import styles from "../css/Video.css";
import { css } from "@emotion/react";
import npyjs from "./NumpyToJS";

import { encodeText, decodeVideo } from "../api/endpoints";

const Video = () => {
  const override = css`
    margin-top: 2.5vh;
  `;
  const [userInput, setUserInput] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);
  const [count, setCount] = useState(0);
  const [decodedText, setDecodedText] = useState("");
  const [videoLoading, setVideoLoading] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const [decodingLoading, setDecodingLoading] = useState(false);
  const [decodingBatchNumber, setDecodingBatchNumber] = useState(0);
  const [totalBatches, setTotalBatches] = useState(null);

  const preventTyping = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleGenerate = async () => {
    if (userInput == "") {
      setIsGenerated(false);
      return;
    }
    setVideoLoading(true);

    const encodedImages = await encodeText(userInput);

    setImageURLs(encodedImages);
    setVideoLoading(false);

    if (!isGenerated) {
      setIsGenerated(true);
    } else {
      setCount(count => count + 1); // recreates video
    }
  };

  const handleChange = e => {
    setUserInput(e.target.value);
  };

  const handleDecode = async () => {
    setDecodedText("");
    setDecodingLoading(true);

    const batchSize = 10; // number of characters to decode at once
    const imagesPerChar = 3; // number of images to decode per character

    setTotalBatches(Math.ceil((1.0 * userInput.length) / batchSize));

    const numpyURLs = imageURLs.map(url =>
      url
        .replace("keyboard-dataset", "keyboard-dataset-preprocessed-80-numpy")
        .replace("jpg", "npy")
    );

    const getNumpyData = async url => {
      let data = await fetch(url);
      let array = await data.arrayBuffer();
      array = await new npyjs().parse(array);
      return array.data;
    };

    const getData = async URLs => {
      return Promise.all(URLs.map(url => getNumpyData(url)));
    };

    // element i is the list of URLs corresponding to the ith repeat of the text
    // as encoded in the images
    // if we have text "abc", our images are arranged as "abcabcabc";
    // element 0 refers to the first 3 images (corresponding to the first
    // instance of "abc")

    console.log(numpyURLs);
    const numpyURLTextIterations = [];
    for (let i = 0; i < numpyURLs.length; i += userInput.length) {
      console.log(numpyURLTextIterations);
      numpyURLTextIterations.push(numpyURLs.slice(i, i + userInput.length));
    }
    console.log(numpyURLTextIterations);

    // for proper intergation with our majority voting, we want to decode all the
    // images for a given character at once

    let builtDecodingString = "";

    // iterate over batches of characers
    for (let i = 0; i < userInput.length; i += batchSize) {
      setDecodingBatchNumber(i / batchSize + 1);
      // get the first batchSize characters out of all iterations
      let batchURLs = numpyURLTextIterations.map(iteration =>
        iteration.slice(i, i + batchSize)
      );

      let textToDecode = userInput.slice(i, i + batchSize);

      batchURLs = batchURLs.flat(); // numpy URLs corresponding to this batch's text

      const batchNumpyArrays = await getData(batchURLs); // acquire matrices

      setDecodingLoading(true);

      const predictedText = await decodeVideo(
        batchURLs,
        batchNumpyArrays,
        textToDecode
      );

      builtDecodingString = builtDecodingString.concat(predictedText);
    }

    setDecodedText(builtDecodingString);

    // clean up
    setDecodingLoading(false);
    setDecodingBatchNumber(0);
  };

  return (
    <Grid padded>
      <Grid.Row centered>
        <Grid.Column centered computer={12} tablet={12} mobile={16}>
          <center>
            <h2>Try it yourself!</h2>
          </center>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          Try encode and decode using Emorfi with this application. Enter a
          piece of text in English, and the characters will be converted into
          patterns and assembled into a video. To decode, the app will use a
          trained CNN decoder to decode the image frames sequentially. Due to
          technical limitations, it is only suitable for encoding short text.
          For longer text or other types of messages, please use our original
          code on{" "}
          <a
            href="https://github.com/youlab/Information_encoding"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a>
          .
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered padded>
        <Grid.Column verticalAlign="middle" computer={4} tablet={5} mobile={16}>
          <Form>
            <div style={{ textAlign: "left", width: "100%" }}>
              <label>{userInput.length} / 250 characters used</label>
            </div>
            <TextArea
              placeholder="Enter your text here"
              onChange={e => handleChange(e)}
              rows={7}
              maxLength={250}
            />
          </Form>
          <Button
            attached="bottom"
            secondary
            onClick={() => handleGenerate()}
            loading={videoLoading}
          >
            Generate video
          </Button>
        </Grid.Column>
        <Grid.Column
          verticalAlign="middle"
          computer={4}
          tablet={5}
          mobile={14}
          padded
        >
          <br />
          <br />
          <center style={{ padding: "3rem 1rem 5rem 1rem" }}>
            <div className="box">
              <div className="circle-3">
                {isGenerated && (
                  <PhotoSlideshow
                    duration={300}
                    imageURLs={imageURLs}
                    active={true}
                    showControls={true}
                    className="video"
                    key={count}
                    encodedText={userInput}
                  />
                )}
              </div>
            </div>
          </center>
        </Grid.Column>
        <Grid.Column
          verticalAlign="middle"
          computer={4}
          tablet={5}
          mobile={16}
          padded
        >
          <Form>
            <br />
            <TextArea
              value={decodedText}
              onKeyPress={e => preventTyping(e)}
              placeholder="Decoded Text"
              disabled
              rows={7}
            />
          </Form>
          <Button
            attached="bottom"
            secondary
            onClick={() => handleDecode()}
            disabled={videoLoading || !isGenerated}
          >
            <div>
              {decodingLoading
                ? decodingBatchNumber == 0 // still processing before first batch
                  ? "Loading"
                  : `Decoding batch ${decodingBatchNumber} of ${totalBatches}`
                : //`${((decodingBatchNumber - 1) / totalBatches) * 100}%`
                  "Decode video"}
              {decodingLoading && (
                <Loader
                  active
                  inline
                  inverted
                  className="button-loader"
                  style={{ marginLeft: "1.5rem", padding: "0" }}
                />
              )}
            </div>
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider />
    </Grid>
  );
};

export default Video;

// relic of our package reliant past

/*

  const zoomOutProperties = {
    duration: 150,
    transitionDuration: 0,
    infinite: true,
    arrows: false,
    indicators: false
  };

<div className="slide-container">
  <Zoom {...zoomOutProperties} key={count}>
    {imageURLs.map((each, index) => (
      <img
        key={`${count}-${index}`}
        className="video"
        src={each}
      />
    ))}
  </Zoom>
</div> */

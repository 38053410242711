import React from "react";
import styles from "../css/Description.css";
import { Grid, Image } from "semantic-ui-react";

const References = () => {
  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <center>
            <h2> References </h2>
          </center>
          <br />
          [1] Wolfram, S. in Conference on the Theory and Application of
          Cryptographic Techniques. 429-432 (Springer). <br /> <br />
          [2] Deng, P., de Vargas Roditi, L., Van Ditmarsch, D. & Xavier, J. B.
          The ecological basis of morphogenesis: branching patterns in swarming
          colonies of bacteria. New journal of physics 16, 015006 (2014).
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default References;

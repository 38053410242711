import { client } from "./axiosClient";

export const encodeText = async text => {
  // returns a list of URLS for images corresponding to encoded characters
  // the returned list is n times as long as the text (since we encode each char
  // n times for majority voting)
  const { data } = await client.post(
    "generateList",
    { text },
    {
      headers: { "Content-Type": "application/json" }
    }
  );

  return data;
};

export const decodeVideo = async (
  batchURLs,
  batchNumpyArrays,
  textToDecode
) => {
  const { data } = await client.post(
    "/decodeFromList",
    {
      URLs: batchURLs,
      numpyArrays: batchNumpyArrays,
      encodedText: textToDecode
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return data.predicted_text;
};

export const generateGrowthColony = async radius => {
  const { data } = await client.post(
    "/growth",
    { growthRadius: radius },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return data;
};

export default { encodeText, decodeVideo, generateGrowthColony };

import React from "react";
import HeaderComponent from "../components/HeaderComponent";
import Footer from "../components/Footer";
import Description from "../components/Description";
import Navbar from "../components/Navbar";
import References from "./References";
import { Grid, Container, Divider } from "semantic-ui-react";

const PageLayout = props => {
  return (
    <Container fluid>
      <Grid padded centered>
        <Grid.Row>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <HeaderComponent />
            {/*<Navbar />*/}
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            {props.children}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <References />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <Divider />
            <Footer />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
export default PageLayout;

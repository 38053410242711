import React from "react";
import { Header, Grid } from "semantic-ui-react";
import styles from "../css/HeaderComponent.css";

const HeaderComponent = () => {
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column
          computer={12}
          tablet={12}
          mobile={16}
          style={{ padding: "1.5rem" }}
        >
          <center>
            <h1>
              Distributed information encoding and decoding using self-organized
              spatial patterns
            </h1>
          </center>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default HeaderComponent;

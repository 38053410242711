import React from "react";
import { Grid, Container, Divider } from "semantic-ui-react";
import HeaderComponent from "./HeaderComponent";
import Description from "./Description";
import Navbar from "./Navbar";
import Decoder from "./Decoder";
import Encoder from "./Encoder";
import Encryption from "./Encryption";
import Footer from "./Footer";
import PatternDevelopment from "./PatternDevelopment";
import Video from "./Video";

const MainContent = () => {
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={10} tablet={8} mobile={16} largeMonitor={5}>
            <HeaderComponent />
            <Navbar />
            <Description />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            <PatternDevelopment />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Encoder />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Decoder />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Encryption />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Video />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Footer />
    </Container>
  );
};

export default MainContent;

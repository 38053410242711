import React from "react"

const Decoder = () => {
    return (
        <div>
            <div class="ui raised very padded container center aligned massive segment">
                <br />
                <div class="ui grid">
                    <div class="four wide column">
                        <h1>Decoder</h1>
                        <h3>1. Upload your pattern</h3>
                        <h3>2. Select the corresponding parameters</h3>
                        <h3>3. Click decode!</h3>
                    </div>
                    <div class="four wide column">
                        <h3>upload/use the generating pattern</h3>
                        <br /><br /><br /><br /><br /><br /><br /><br />
                        <div class="ui yellow button" tabindex="0">
                            Decode
                        </div>
                    </div>
                    <div class="four wide column">
                        <h3>Decoded message</h3>
                    </div>
                    <div class="four wide yellow column">
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Decoder
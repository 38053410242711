import React from "react";
import styles from "../css/Description.css";
import { Grid, Image } from "semantic-ui-react";
import ReactPlayer from "react-player";
import Video from "../assets/website_video1.mp4";
import ListFile from "../assets/list.pdf";
import NewVideo from "../assets/newWebsiteVideo.mp4";

import colony1 from "../images/colony1.jpg";
import colony2 from "../images/colony2.jpg";
import colony3 from "../images/colony3.jpg";
import colony4 from "../images/colony4.jpg";
import encodingScheme from "../images/encoding_decoding_scheme.png";

const Description = () => {
  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <h2> Overview</h2>
          <br />
          Dynamical systems concern about the spatiotemporal evolution of a
          point. They often generate distinct outputs according to different
          initial conditions, and one could infer the corresponding input given
          an output. This property captures the essence of information encoding
          and decoding.
          <br />
          <br />
          <br />
          While chaotic systems have been proposed for secure encoding and
          decoding [1], they are extremely sensitive to perturbation, such that
          small changes in initial condition can lead to drastically different
          outputs (i.e. avalanche effect). On one hand, this property lays the
          foundation for information security, as it is difficult for attackers
          to decode the message without prior knowledge of the system. On the
          other hand, noise and error are often unavoidable in encoding, so the
          high sensitivity could make decoding challenging for the designated
          recipient.
          <br />
          <br />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column
          centered
          computer={12}
          tablet={12}
          mobile={16}
          style={{ padding: "1rem 0" }}
        >
          <center>
            <Image.Group>
              <Image src={colony1} alt="Colony 1" className="colony-image" />
              <Image src={colony2} alt="Colony 2" className="colony-image" />
              <Image src={colony3} alt="Colony 3" className="colony-image" />
              <Image src={colony4} alt="Colony 4" className="colony-image" />
            </Image.Group>
          </center>
          <center>
            Figure 1: Bacteria form diverse self-organized colony patterns.
          </center>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          To provide both reliability and security, we propose to use biological
          self-organized patterns that are often more convergent. That is, for
          the same or similar input configurations and environmental conditions,
          the final patterns share global similarity despite local variances
          which are caused by random noise. This property is sometimes referred
          to as “edge of chaos”.
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" centered>
        <Grid.Column computer={8} tablet={8} mobile={10}>
          To this end, we demonstrate the use of a model pattern-formation
          system mimicking Pseudomonas aeruginosa branching patterns, and
          establish distributed information encoding. Coupled with machine
          learning (ML) mediated decoding, our system illustrates a scalable
          strategy for information encoding and decoding with quantifiable
          reliability and security.
        </Grid.Column>
        <Grid.Column centered computer={4} tablet={4} mobile={6}>
          <center>
            <div
              style={{
                maxWidth: "20rem"
              }}
            >
              <ReactPlayer
                url={NewVideo}
                width="100%"
                loop={true}
                controls={true}
                style={{
                  margin: "0 0 8px 0",
                  maxWidth: "10rem",
                  maxHeight: "10rem"
                }}
              />
              Video 1: The paragraph on the left is encoded into this video.{" "}
            </div>
          </center>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered style={{ paddingBottom: "2rem" }}>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <h2> How does it work? </h2>
          <br />
          <img
            src={encodingScheme}
            alt="Encoding/decoding scheme"
            style={{ width: "100%" }}
          />
          <center>Figure 2. Pattern-based encoding-decoding scheme.</center>
          <br />
          <br />
        </Grid.Column>
        <Grid.Column computer={7} tablet={7} mobile={9}>
          <div className="encode-text">
            To encode, we convert each character (e.g., letters, numbers,
            punctuations and etc.) into a unique initial configuration. Then, we
            seed the cells within the configuration and let them grow into a
            visually complex pattern.
          </div>
        </Grid.Column>
        <Grid.Column computer={5} tablet={5} mobile={7}>
          <div className="decode-text">
            To decode, we train a convolutional neural network (CNN) to
            distinguish the patterns via multi-class classification.
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <h2> Why is it secure? </h2>
          <br />
          Information security could be compromised in several ways. 1) A
          malicious attacker has access to the pattern generator and simulation
          parameters, so he or she can construct the training dataset. 2) The
          trained decoder is leaked. 3) Data leakage happens over time, such
          that an attacker can also reconstruct a CNN to decode. While the first
          two possibilities can only be prevented by proper protection of the
          code and data, our data-driven decoding method provides a solution to
          combat the data leakage issue. The availability and amount of training
          data are crucial for decoding accuracy and are tunable through system
          dynamics and encoding settings. Thus, we can leverage the fact that
          the platform end-users and malicious attackers have different access
          to data to make sure the user with sufficient data can reliably decode
          while an attacker with scarce data cannot train an accurate decoder.
          As the platform could be overused causing sufficient data leakage, we
          can periodically update the training data and the corresponding CNN,
          due to the customizable nature of the encoding scheme.
          <br />
          <br />
          <br />
          Other important properties of self-organized patterns can also be used
          to further enhance the security. For example, factors that critically
          impact the patterning process can function as secret keys to encrypt
          patterns, and the biological noise fingerprint can be used to
          authenticate pattern integrity.
          <br />
          <br />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <h2> Scalability and generalizability </h2>
          <br />
          Our platform is scalable and generalizable for various applications.
          To accommodate complex information, one can tune the system dynamics
          and encoding settings to maximize the encoding capacity. Ensemble
          techniques, such as majority voting and stacking, can be implemented
          to improve decoding accuracy and estimate prediction uncertainty.
          <br />
          <br />
          <br />
          In order to encode English text, we constructed a collection of
          patterns using the 100 printable ASCII characters (
          <a download="list.pdf" href={ListFile}>
            download the complete list
          </a>
          ). These patterns in essence constitutes a new, digitally generated
          coding scheme, which we call Emorfi. We were able to encode and decode
          a number of famed writings in Emorfi with very high accuracy (above
          99%). We envision the platform could be extended to other languages,
          and be applicable for communicating science and protecting
          intellectual properties by incorporating Greek alphabet, mathematical
          symbols, nucleic acid bases and etc.
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Description;

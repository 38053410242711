import React from 'react'
import { Header, Button } from 'semantic-ui-react'
import styles from '../css/PatternDevelopment.css'

const PatternDevelopment = () => {
    return (
        <div>
            <Header as='h2'>
                <Header.Content className='subheader-1'>
                    Pattern development
                    </Header.Content>
                <hr></hr>
            </Header>
            <div className='flex-row'>
                <div className='parameter-box'>
                    <div className='flex-row' style={{ fontWeight: 'bold', marginTop: '1vh' }}>Dictionary length</div>
                    <div className='flex-row'>
                        <div className='flex-column'>2 bits</div>
                        <div className='flex-column' style={{ margin: '1.5vh 2vw' }}>4 bits</div>
                        <div className='flex-column'>6 bits</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold', border: 'solid 2px black', margin: '0 1.5vw', textAlign: 'center' }}>
                        ENTER THE CHARACTER TO ENCODE
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold', marginTop: '1.5vh' }}>Spot radius</div>
                    <div className='flex-row'>
                        <div className='flex-column'>5</div>
                        <div className='flex-column' style={{ margin: '1.5vh 3vw' }}>15</div>
                        <div className='flex-column'>30</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold' }}>Spacing</div>
                    <div className='flex-row'>
                        <div className='flex-column'>10</div>
                        <div className='flex-column' style={{ margin: '1.5vh 3vw' }}>20</div>
                        <div className='flex-column'>50</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold' }}>Relative acting distance (d1/d2)</div>
                    <div className='flex-row' style={{ marginTop: '1.5vh' }}>
                        <div className='flex-column'>0.35</div>
                        <div className='flex-column' style={{ margin: '0 2.5vw' }}>0.4</div>
                        <div className='flex-column' style={{ marginRight: '2.5vw' }}>0.45</div>
                        <div className='flex-column'>0.5</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold', marginTop: '1.5vh' }}>Relative strength (b)</div>
                    <div className='flex-row' style={{ marginTop: '1.5vh' }}>
                        <div className='flex-column'>4</div>
                        <div className='flex-column' style={{ margin: '0 2.5vw' }}>4.5</div>
                        <div className='flex-column' style={{ marginRight: '2.5vw' }}>6</div>
                        <div className='flex-column'>7.5</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold', marginTop: '1.5vh' }}>Seeding noise</div>
                    <div className='flex-row' style={{ marginTop: '1.5vh' }}>
                        <div className='flex-column'>None</div>
                        <div className='flex-column' style={{ margin: '0 1.7vw' }}>Low</div>
                        <div className='flex-column' style={{ marginRight: '1.7vw' }}>Medium</div>
                        <div className='flex-column'>High</div>
                    </div>
                    <div className='flex-row' style={{ fontWeight: 'bold', marginTop: '1.5vh' }}>Growth noise</div>
                    <div className='flex-row' style={{ marginTop: '1.5vh' }}>
                        <div className='flex-column'>None</div>
                        <div className='flex-column' style={{ margin: '0 1.7vw' }}>Low</div>
                        <div className='flex-column' style={{ marginRight: '1.7vw' }}>Medium</div>
                        <div className='flex-column'>High</div>
                    </div>
                </div>
                <div className='flex-column'>
                    <span>Seeding configuration</span>
                    <div className='initial-config'>
                        <div className='circle-1'>

                        </div>
                    </div>
                    <Button secondary>Generate initial configuration</Button>
                </div>
                <div className='flex-column'>
                    <span>Pattern at t = 0</span>
                    <div className='encode'>
                        <div className='circle-2'>

                        </div>
                    </div>
                    <Button secondary>Encode</Button>
                </div>
            </div>
        </div>
    )
}

export default PatternDevelopment
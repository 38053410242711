import React from "react";
import PageLayout from "../components/PageLayout";

import Description from "./Description";
import Video from "./Video";

const HomePage = () => {
  return (
    <PageLayout>
      <Description />
      <Video />
    </PageLayout>
  );
};
export default HomePage;

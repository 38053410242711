import React from "react"
import styles from "../css/Subheader.css"
import { Header } from "semantic-ui-react"

const Encryption = () => {
    return (
        <div>
            <Header as='h2'>
                <Header.Content className='subheader-1'>
                    Encryption
                </Header.Content>
                <hr></hr>
            </Header>
            <p className='description-2'>
                We combined the above encoding-decoding platform with encryption,
                where a secret key is implemented during encoding and successful decoding
                requires the correct key. For pattern formation systems, the geometry of
                the patterning domain is a feasible choice of secret key as it can influence
                the pattern formation and is easily tunable. In our system, the boundary exhibits
                a time-invariant, long-range, and weak inhibitive force on colony expansion. As
                the force is anisotropic due to asymmetric boundary geometry, the patterns can be
                encrypted by the domain shape.
            </p>
        </div>
    )
}

export default Encryption
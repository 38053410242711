import React from "react";
import { Menu, Grid, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <Container>
        <Grid centered verticalAlign="middle" fluid>
          <Grid.Column>
            <Link to="/">Home</Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="/GrowthColony">GrowthColony</Link>
          </Grid.Column>
        </Grid>
      </Container>
      <div class="ui hidden divider"></div>
    </div>
  );
};

export default Navbar;

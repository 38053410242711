import React from "react"
import { Grid, Header } from 'semantic-ui-react';
import styles from '../css/Subheader.css'

const Encoder = () => {
    return (
        <div>
            <Header as='h2'>
                <Header.Content className='subheader-1'>
                    Encoding and decoding
                </Header.Content>
                <hr></hr>
            </Header>

            
            <div class="ui raised very padded container center aligned massive segment">
                <div class="ui grid">
                    <div class="four wide column">
                        <h1>Encoder</h1>
                        <h3>1. Enter the plain text to ecode</h3>
                        <h3>2. Enter the desired parameters</h3>
                        <h3>3. Click encode!</h3>
                    </div>
                    <div class="four wide column">
                        <br /><br /><br /><br />
                        <div class="ui mini icon input">
                            <input type="text" placeholder="Enter Plain Text">
                            </input>
                            <i class="search icon"></i>
                        </div>
                    </div>
                    <div class="four wide column">
                        <h3>encoded image</h3>
                        <br /><br /><br /><br /><br /><br /><br /><br />
                        <div class="ui blue button" tabindex="0">
                            Encode
                        </div>
                    </div>
                    <div class="four wide blue column">
                        <h3>parameter 1</h3>
                        <br />
                        <h3>parameter 2</h3>
                        <br />
                        <h3>parameter 3</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Encoder
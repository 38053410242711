import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import styles from "../css/Video.css";

const PhotoSlideshow = props => {
  let { imageURLs, duration, active, encodedText, showControls } = props;

  imageURLs = imageURLs && imageURLs.length > 0 ? imageURLs : [];

  const [isActive, setIsActive] = useState(active);
  const [images, setImages] = useState(imageURLs);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        // update the active image index every `duration` milliseconds
        setActiveImageIndex(activeImageIndex =>
          // reset index to first image if we have already looped through the
          // whole array of images
          activeImageIndex + 1 == images.length ? 0 : activeImageIndex + 1
        );
      }, duration);
    }
    return () => {
      clearInterval(interval);
    }; // clean up
  }, [isActive, activeImageIndex]);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const incrementActiveImage = () => {
    setActiveImageIndex(
      activeImageIndex + 1 == images.length ? 0 : activeImageIndex + 1
    );
  };

  const decrementActiveImage = () => {
    setActiveImageIndex(
      activeImageIndex - 1 == -1 ? images.length - 1 : activeImageIndex - 1
    );
  };

  const controls = (
    <div style={{ color: "#000000", padding: "1rem 0", fontSize: "1.5rem" }}>
      <center>
        <Icon
          className="chevron left icon arrow-button"
          onClick={decrementActiveImage}
          style={{ position: "absolute", left: "2rem" }}
        />
        <span style={{ width: "4rem", padding: "0 2rem" }}>
          {/* encodedText[activeImageIndex % encodedText.length]
            // use to display encoded character */}
          {activeImageIndex + 1
          // use to display index of image
          }
        </span>
        <Icon
          className="chevron right icon cursor arrow-button"
          onClick={incrementActiveImage}
          style={{ position: "absolute", right: "2rem" }}
        />
      </center>
    </div>
  );

  return (
    <div style={{ display: "block" }}>
      <div
        style={{
          position: "relative",
          padding: "none"
        }}
      >
        <div className="pause-overlay" onClick={toggleActive}>
          <center>
            <Icon
              className={isActive ? "pause circle" : "play circle"}
              size="huge"
              color="white"
            />
          </center>
        </div>
        <img
          src={imageURLs[activeImageIndex]}
          {...props}
          className={"slideshow-image " + props.className}
          alt="Encoded video"
        />
      </div>
      {showControls && controls}
    </div>
  );
};

export default PhotoSlideshow;

import React from "react";
import { Menu, Container, Grid, Divider } from "semantic-ui-react";
import styles from "../css/Footer.css";

const Footer = () => {
  return (
    <Grid padded centered>
      <Grid.Row centered>
        <Grid.Column
          computer={11}
          tablet={9}
          mobile={16}
          textAlign="center"
          style={{ marginBottom: "2rem" }}
        >
          <b>Acknowledgements</b>
          <br />
          <br />
          This website was built by Yasa Baig, Minjun Kwak, Jia Lu, Nicole
          Moiseyev, Shari Tian, and Alison Zhang.
          <br />
          <br />
          If you see mistakes or want to suggest changes, please contact
          jia.lu@duke.edu
        </Grid.Column>

        <Grid.Column computer={14} tablet={16} mobile={16}>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column
          computer={14}
          tablet={8}
          mobile={16}
          style={{ marginBottom: "2rem" }}
        >
          <center>
            Copyright &copy; 2021{" "}
            <a href="https://youlab.bio" target="_blank" rel="noreferrer">
              You Lab
            </a>
            . All Rights Reserved.{" "}
          </center>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Footer;
